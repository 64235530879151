import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth/auth.service';
import { AuthRequestsService } from '../services/auth/auth-requests.service';
import { AuthStateService } from '../services/auth/auth-state.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private authRequestsService: AuthRequestsService,
    private authStateService: AuthStateService,
    private toastrService: NbToastrService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      switchMap((event) => {
        if (!(event instanceof HttpResponse) || !request.url.includes(environment.authModuleApi)) {
          return [event];
        }

        if (event.body instanceof Blob || event?.body.success) {
          return [event];
        }

        if (event?.body.error.type !== 'AUTHENTICATION') {
          this.toastrService.danger(
            `Error: ${event?.body.error.type}`,
            `Something went wrong! ${event?.body.error.description}`
          );

          if (event?.body.error.type == 'UNKNOWN') {
            this.authService.logout();
          }

          return [event];
        }

        if (event.url?.includes(this.authRequestsService.refreshEndpoint)) {
          this.authService.logout();
          return [event];
        }

        return this.handleAuthenticationError(request, next);
      }),
      catchError((error: HttpErrorResponse) => {
        this.toastrService.danger(`Error: ${error?.error.error}`, `Something went wrong!`);
        return throwError(() => error);
      })
    );
  }

  private handleAuthenticationError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.authStateService.adminCredentialSnapshot?.refreshToken;
      if (refreshToken) {
        return this.authRequestsService.refreshTokenRequest(refreshToken).pipe(
          switchMap((response) => {
            if (response.data) {
              this.authStateService.setAdminCredential({
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken
              });
              this.refreshTokenSubject.next(response.data.accessToken);
              this.isRefreshing = false;
              return next.handle(request);
            } else {
              this.authService.logout();
              return throwError(() => new HttpErrorResponse({ status: 401 }));
            }
          }),
          catchError((error) => {
            this.isRefreshing = false;
            this.authService.logout();
            return throwError(() => error);
          })
        );
      } else {
        this.authService.logout();
        return throwError(() => new HttpErrorResponse({ status: 401 }));
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap(() => next.handle(request))
      );
    }
  }
}
