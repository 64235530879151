<div class="header-container">
  <img class="logo" (click)="navigateHome()" src="../../../../assets/images/gaimin-logo.svg" alt="Gaimin" />
  <div class="buttons-container">
    <button
      *ngIf="authStateService.isLoggedIn$ | async"
      (click)="logout()"
      nbButton
      status="primary"
      outline
      class="logout">
      Logout
    </button>
    <nb-icon icon="menu-outline" (click)="toggleSidebar()"></nb-icon>
  </div>
</div>
