<nb-card>
  <nb-card-header>Confirm action</nb-card-header>
  <nb-card-body> Are you sure you want to {{ message }}? </nb-card-body>
  <nb-card-footer>
    <div class="row">
      <div class="col-sm-6">
        <button (click)="confirm()" fullWidth nbButton status="success">Yes</button>
      </div>
      <div class="col-sm-6">
        <button (click)="close()" fullWidth nbButton status="danger">No</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
