import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthTokens, Response } from '../../interfaces';
import { USER_ROLES } from '../../enums';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestsService {
  refreshEndpoint = `/auth/token/refresh`;

  constructor(private http: HttpClient) {}

  getUserRoleRequest() {
    return this.http.get<Response<USER_ROLES>>(`${environment.authModuleApi}/user/get-role`);
  }

  refreshTokenRequest(refreshToken: string): Observable<Response<AuthTokens>> {
    return this.http.post<Response<AuthTokens>>(`${environment.authModuleApi}${this.refreshEndpoint}`, {
      refreshToken
    });
  }
}
