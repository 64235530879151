import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'ngx-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {
  @Input() toggleName: string | null = null;
  @Input() toggleState: boolean = true;
  @Input() borderStyle: boolean = true;

  @Output() turnedOnStatus = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleChange() {
    this.toggleState = !this.toggleState;
    this.turnedOnStatus.emit(this.toggleState);
  }
}
