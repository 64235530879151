import { Injectable } from '@angular/core';
import { PaginationInstance } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  getPaginationRangeStart(paginationConfig: PaginationInstance): number {
    return (paginationConfig.currentPage - 1) * paginationConfig.itemsPerPage + 1;
  }

  getPaginationRangeEnd(paginationConfig: PaginationInstance): number {
    const end = paginationConfig.currentPage * paginationConfig.itemsPerPage;
    return Math.min(end, paginationConfig.totalItems);
  }

  getPaginationTotalItems(paginationConfig: PaginationInstance): number {
    return paginationConfig.totalItems;
  }
}
