import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { AuthStateService } from './auth/auth-state.service';
import { USER_ROLES } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu: NbMenuItem[] = [];

  constructor(private authStateService: AuthStateService) {
    this.authStateService.currentUserRole$.subscribe({
      next: () => {
        this.updateMenu();
      }
    });
  }

  updateMenu() {
    this.menu = [
      { title: 'AUTH MODULE', group: true },
      {
        hidden: this.authStateService.checkUserRights(USER_ROLES.ROLE_ADMIN),
        link: '/login',
        icon: 'log-in-outline',
        title: 'Auth Module Login'
      },
      {
        hidden: !this.authStateService.checkUserRights(USER_ROLES.ROLE_ADMIN),
        title: 'Users List',
        icon: 'people-outline',
        link: '/users'
      }
    ];
  }
}
