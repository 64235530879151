import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { Response, StatusResponse, UsersListData, UserData } from '../interfaces';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService
  ) {}

  resendVerificationEmail(data: UserData) {
    const dialogRef = this.dialogService.open(ConfirmationModalComponent, {
      context: {
        message: 'resend verification email'
      }
    });

    dialogRef.componentRef.instance.confirmRequest.subscribe(() => {
      if (data.id) {
        this.resendVerificationEmailRequest(data.email).subscribe((res) => {
          if (res.success) {
            this.toastrService.success('', 'Verification email has been successfully resent!');
          }
        });
      }
    });
  }

  resetPassword(data: UserData) {
    const dialogRef = this.dialogService.open(ConfirmationModalComponent, {
      context: {
        message: "reset user's password"
      }
    });

    dialogRef.componentRef.instance.confirmRequest.subscribe(() => {
      if (data.id) {
        this.resetPasswordRequest(data.email).subscribe((res) => {
          if (res.success) {
            this.toastrService.success('', 'Password reset email has been sent!');
          }
        });
      }
    });
  }

  getUsersRequest(params: {}): Observable<Response<UsersListData>> {
    return this.http.post<Response<UsersListData>>(`${environment.authModuleApi}/admin/users`, params);
  }

  getUserByIdRequest(id: number): Observable<Response<UserData>> {
    return this.http.get<Response<UserData>>(`${environment.authModuleApi}/admin/user/${id}`);
  }

  resetPasswordRequest(email: string): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.authModuleApi}/admin/password/request-reset`, {
      email: email
    });
  }

  resendVerificationEmailRequest(email: string): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(`${environment.authModuleApi}/admin/resend-email`, { email: email });
  }
}
