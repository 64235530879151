<div class="content">
  <div class="content__buttons">
    <button nbButton status="primary" outline routerLink="/users" class="edit-item__back">
      <nb-icon icon="arrow-back-outline"></nb-icon> Back
    </button>
  </div>
  <nb-card class="dashboard-content">
    <nb-card-body>
      <header class="table-title">
        <div class="wrapper">
          <img
            [src]="data && data.imageUrl ? data.imageUrl : 'assets/icons/default-user-image.png'"
            onError="this.src = 'assets/icons/default-user-image.png'"
            class="avatar"
            alt="User" />
          <h2>User Details</h2>
        </div>
      </header>

      <ng-container *ngIf="data; else emptyTemplate">
        <div class="content__data">
          <div class="data-actions">
            <button nbButton status="primary" outline (click)="userService.resendVerificationEmail(data)">
              <nb-icon icon="email-outline"></nb-icon> Send Verification Email
            </button>
            <button nbButton status="info" outline (click)="userService.resetPassword(data)">
              <nb-icon icon="key" pack="fas"></nb-icon> Reset Password
            </button>
          </div>
          <div class="data-list">
            <span class="name">User ID:</span><span class="value">{{ data.id }}</span>
            <span class="name">First Name:</span><span class="value">{{ data.firstName }}</span>
            <span class="name">Last Name:</span><span class="value">{{ data.lastName }}</span>
            <span class="name">Email:</span
            ><span class="value"
              >{{ data.email }}
              <nb-icon
                icon="copy"
                pack="fas"
                class="fa-regular action"
                status="primary"
                (click)="onCopy(data.email)"></nb-icon>
            </span>
            <span class="name">Username:</span><span class="value">{{ data.username || '-' }}</span>
            <span class="name">Google Account:</span>
            <span class="value">
              <nb-icon *ngIf="data.googleId; else empty" icon="checkmark-square-2-outline" status="success"></nb-icon>
            </span>
            <span class="name">Discord Account:</span>
            <span class="value">
              <nb-icon *ngIf="data.discordId; else empty" icon="checkmark-square-2-outline" status="success"></nb-icon>
            </span>
            <span class="name">Wallet Address:</span
            ><span class="value"
              >{{ data.walletAddress || '-' }}
              <nb-icon
                *ngIf="data.walletAddress"
                icon="copy"
                pack="fas"
                class="fa-regular action"
                status="primary"
                (click)="onCopy(data.walletAddress)"></nb-icon
            ></span>
            <ng-template #empty> - </ng-template>
          </div>
        </div>
      </ng-container>
    </nb-card-body>
    <app-loader class="form-loader"></app-loader>
  </nb-card>

  <ng-template #emptyTemplate>
    <div class="empty-template" *ngIf="!(loaderService.loading$ | async)">User with this ID is not found.</div>
  </ng-template>
</div>
