import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input() message: string = '';
  @Output() confirmRequest: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected ref: NbDialogRef<ConfirmationModalComponent>) {}

  close() {
    this.ref.close();
  }

  confirm() {
    this.ref.close();
    this.confirmRequest.emit();
  }
}
