import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../shared/services/loader.service';
import { UserService } from '../../../shared/services/user.service';
import { AdditionalService } from '../../../shared/services/additional.service';
import { UserData } from '../../../shared/interfaces';

@Component({
  selector: 'ngx-single-user-page',
  templateUrl: './single-user-page.component.html',
  styleUrls: ['./single-user-page.component.scss']
})
export class SingleUserPageComponent implements OnInit {
  @AutoUnsubscribe()
  private paramsSubs: Subscription | undefined;
  private itemId: number | undefined;

  data: UserData | undefined;

  constructor(
    public userService: UserService,
    public loaderService: LoaderService,
    private additionalService: AdditionalService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.paramsSubs = this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.itemId = +params['itemId'];

        this.userService.getUserByIdRequest(this.itemId).subscribe((res) => {
          if (res?.success && res.data) {
            this.data = res.data;
          }
        });
      }
    });
  }

  onCopy(copyText: string) {
    this.additionalService.copyToClipboard(copyText);
  }
}
