<div class="switch-container" [ngClass]="{ 'with-border': borderStyle }">
  <div class="switch-holder">
    <div *ngIf="toggleName" class="switch-label">
      <span>{{ toggleName }}</span>
    </div>
    <div class="switch-toggle" (click)="toggleChange()">
      <input type="checkbox" [checked]="toggleState" />
      <label></label>
    </div>
  </div>
</div>
