import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AuthRequestsService } from './auth-requests.service';
import { AuthStateService } from './auth-state.service';
import { AuthTokens } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private authStateService: AuthStateService,
    private authRequestsService: AuthRequestsService,
    private router: Router,
    private location: Location
  ) {}

  init() {
    this.authStateService.adminCredential$
      .pipe(
        filter((value): value is AuthTokens => !!value),
        distinctUntilChanged((prev, curr) => prev.accessToken === curr.accessToken)
      )
      .subscribe((v) => {
        this.getUserRole();
      });

    const adminCredential = localStorage.getItem('adminCredential');
    if (adminCredential) {
      this.authStateService.setAdminCredential(JSON.parse(adminCredential));
    }
  }

  logout() {
    this.authStateService.clearData();
    this.router.navigateByUrl('/');
  }

  clearQueryParams(): void {
    const path = this.router.url.split('?')[0];
    this.location.replaceState(path);
  }

  private getUserRole() {
    this.authRequestsService
      .getUserRoleRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.authStateService.setUserRole(response.data!);
        })
      )
      .subscribe();
  }
}
