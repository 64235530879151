import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthTokens } from '../../interfaces';
import { USER_ROLES } from '../../enums';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  private adminCredentialSrc = new BehaviorSubject<AuthTokens | null>(null);
  public adminCredential$ = this.adminCredentialSrc.asObservable();

  public isLoggedIn$ = this.adminCredential$.pipe(map((data) => !!data));

  private currentUserRoleSrc = new BehaviorSubject<USER_ROLES | null>(null);
  public currentUserRole$ = this.currentUserRoleSrc.asObservable();

  get adminCredentialSnapshot(): AuthTokens | null {
    return this.adminCredentialSrc.value;
  }

  get currentUserRoleSnapshot(): USER_ROLES | null {
    return this.currentUserRoleSrc.value;
  }

  constructor() {}

  setAdminCredential(data: AuthTokens): void {
    localStorage.setItem('adminCredential', JSON.stringify(data));

    this.adminCredentialSrc.next(data);
  }

  setUserRole(data: USER_ROLES): void {
    this.currentUserRoleSrc.next(data);
  }

  checkUserRights(userRole: string) {
    return userRole === this.currentUserRoleSnapshot;
  }

  clearData() {
    localStorage.removeItem('adminCredential');
    this.adminCredentialSrc.next(null);
    this.currentUserRoleSrc.next(null);
  }
}
