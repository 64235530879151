import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AuthStateService } from '../../shared/services/auth/auth-state.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  @AutoUnsubscribe()
  private isLoggedInSubs: Subscription;

  constructor(private authStateService: AuthStateService, private router: Router) {
    this.isLoggedInSubs = this.authStateService.isLoggedIn$.subscribe({
      next: (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.router.navigateByUrl('/users');
        }
      }
    });
  }

  ngOnInit(): void {}

  redirectToAuthModule() {
    const returnURL = btoa(encodeURIComponent(window.location.origin + '/login'));
    const redirectURL = `${environment.authModuleUrl}/signin?returnURL=${returnURL}`;
    window.location.href = redirectURL;
  }
}
