<div class="content-page">
  <div class="row">
    <div class="col-md-12">
      <nb-card class="dashboard-content">
        <nb-card-body>
          <header class="table-title">
            <div class="wrapper">
              <nb-icon icon="people-outline"></nb-icon>
              <h2>Users List</h2>
            </div>
          </header>

          <div class="filters">
            <button nbButton outline size="tiny" class="clear-filter" (click)="onClear()">
              <nb-icon icon="close-outline"></nb-icon> Clear filters
            </button>
            <form [formGroup]="form" class="filters__form">
              <div class="form-group">
                <input type="text" nbInput formControlName="name" placeholder="Name" class="search" />
                <button nbButton status="success" outline size="medium" [disabled]="form.invalid" (click)="onFilter()">
                  <nb-icon icon="search-outline"></nb-icon>
                  Search
                </button>
              </div>
            </form>
          </div>

          <table class="table" *ngIf="tableData?.length > 0; else emptyTemplate">
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Username</th>
                <th>Google</th>
                <th>Discord</th>
                <th>Wallet</th>
                <th class="action-buttons"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tableData | paginate : paginationConfig">
                <td>{{ data.id }}</td>
                <td>{{ data.firstName }} {{ data.lastName }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.username }}</td>
                <td class="center">
                  <nb-icon *ngIf="data.googleId" icon="checkmark-square-2-outline" status="success"></nb-icon>
                </td>
                <td class="center">
                  <nb-icon *ngIf="data.discordId" icon="checkmark-square-2-outline" status="success"></nb-icon>
                </td>
                <td>{{ data.walletAddress }}</td>
                <td class="action-buttons">
                  <button nbButton status="success" outline nbTooltip="User Details" [routerLink]="data.id">
                    <nb-icon icon="info" pack="fas"></nb-icon>
                  </button>
                  <button
                    nbButton
                    status="primary"
                    outline
                    nbTooltip="Resend Verification Email"
                    (click)="userService.resendVerificationEmail(data)">
                    <nb-icon icon="email-outline"></nb-icon>
                  </button>
                  <button
                    nbButton
                    status="info"
                    outline
                    nbTooltip="Reset Password"
                    (click)="userService.resetPassword(data)">
                    <nb-icon icon="key" pack="fas"></nb-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-container" *ngIf="tableData?.length > 0">
            <div class="entries">
              Showing {{ paginationService.getPaginationRangeStart(paginationConfig) }} -
              {{ paginationService.getPaginationRangeEnd(paginationConfig) }} of
              {{ paginationService.getPaginationTotalItems(paginationConfig) }} entries
            </div>
            <pagination-controls (pageChange)="pageChanged($event)" responsive="true"> </pagination-controls>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <app-loader class="form-loader"></app-loader>
  </div>

  <ng-template #emptyTemplate>
    <div class="empty-template" *ngIf="!(loaderService.loading$ | async) && tableData">No results found.</div>
  </ng-template>
</div>
