import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './@core/guards/auth.guard';

import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { UsersListPageComponent } from './pages/users-page/users-list-page/users-list-page.component';
import { SingleUserPageComponent } from './pages/users-page/single-user-page/single-user-page.component';

export const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginPageComponent },
      { path: 'users', component: UsersListPageComponent, canActivate: [AuthGuard] },
      { path: 'users/:itemId', component: SingleUserPageComponent, canActivate: [AuthGuard] },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
